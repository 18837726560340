import React, { useEffect } from "react"
import { connect } from "react-redux"
import DownloadBrochure from "./downloadBrochure"
import styles from "../styles/components/table.module.scss"

const ProductCatalog = ({ products }) => {
  return (
    <div className={styles.wrapper}>
      <DownloadBrochure
        products={products
          .filter(
            product =>
              product.deleted === false || product.deleted === undefined
          )
          .filter(product => product.sku !== "INVSOLGDW5KXXXX")
          .filter(product => product.sku !== "INVGRWOFG5KXXXX")

          .filter(product => product.sku !== "INVSOLS61P1000K")
          .filter(product => product.sku !== "INVSOL1P4000XXX")
          .filter(product => product.sku !== "INVSOL1P5000XXX")
          .filter(product => product.sku !== "INVSOL1PHY5KXXX")
          .filter(product => product.sku !== "INVSOL3P1700XXX")
          .filter(product => product.sku !== "INVSOL3P20KXXXX")
          .filter(product => product.sku !== "INVSOL3P6000XXX")
          .filter(product => product.sku !== "INVSOLS5EH1P5KL")

          .filter(product => product.sku !== "PVMPHSMH400XXXX")
          .filter(product => product.sku !== "PVMPHSMH450XXXX")
          .filter(product => product.sku !== "PVMPHSP540XXXXX")

          .filter(product => product.sku !== "CABSHYDC4MMBXXX")
          .filter(product => product.sku !== "CABSHYDC4MMRXXX")}
      />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    products: state.product.products,
  }
}

export default connect(mapStateToProps)(ProductCatalog)
