import React, { useState, useEffect } from "react"
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFDownloadLink,
  Font,
  PDFViewer,
} from "@react-pdf/renderer"
import { Button, Form } from "react-bootstrap"
import { FaFilePdf, FaDownload } from "react-icons/fa"

import Montserrat from "../fonts/montserrat-regular.ttf"
import MontserratSemiBold from "../fonts/montserrat-semibold.otf"
import MontserratBold from "../fonts/montserrat-bold.ttf"

import logo from "../images/logo.png"
import { formatNumber } from "../state/utils"

import img1 from "../images/1.png"
import img2 from "../images/2.png"
import img3 from "../images/3.png"
import img4 from "../images/4.png"
import img5 from "../images/5.png"
import img6 from "../images/6.png"
import img7 from "../images/7.png"
import img8 from "../images/8.png"
import img9 from "../images/9.png"
import img10 from "../images/10.png"

//coverPages
import cover1 from "../images/coverPages/1.jpg"
import cover2 from "../images/coverPages/2.jpg"
import cover3 from "../images/coverPages/3.jpg"
import cover4 from "../images/coverPages/4.jpg"
import cover5 from "../images/coverPages/5.jpg"
import cover6 from "../images/coverPages/6.jpg"
import cover7 from "../images/coverPages/7.jpg"
import cover8 from "../images/coverPages/8.jpg"
import cover9 from "../images/coverPages/9.jpg"

import cover from "../images/cover.jpg"
import back from "../images/back.jpg"

import logoGray from "../images/logo-gray.png"

const categories = [
  "Solar Inverter",
  "PV Modules",
  "Electrical Accessories",
  "Hardware Accessories",
  "Railings & Accessories",
  "DC & AC Cables",
  "Batteries",
  "Solar Water Pump",
  "Tools & Accessories",
]

const categoryToCoverMap = {
  "Solar Inverter": cover1,
  "PV Modules": cover2,
  "Electrical Accessories": cover3,
  "Hardware Accessories": cover4,
  "Railings & Accessories": cover5,
  "DC & AC Cables": cover6,
  Batteries: cover7,
  "Solar Water Pump": cover8,
  "Tools & Accessories": cover9,
}

Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: Montserrat,
    },
    {
      src: MontserratSemiBold,
    },
    {
      src: MontserratBold,
    },
  ],
})

const DownloadBrochure = ({ products }) => {
  const [isClient, setClient] = useState(false)
  const [showDownloadLink, setShowDownloadLink] = useState(false)
  const [selectedCategories, setSelectedCategories] = useState([])
  const [generatePdf, setGeneratePdf] = useState(false)

  useEffect(() => {
    setClient(true)
    setShowDownloadLink(true)
  }, [])

  const handleCategoryChange = category => {
    setSelectedCategories(prevSelectedCategories =>
      prevSelectedCategories.includes(category)
        ? prevSelectedCategories.filter(cat => cat !== category)
        : [...prevSelectedCategories, category]
    )
  }

  const handleSubmit = () => {
    setGeneratePdf(true)
  }

  const handleDownloadComplete = () => {
    setSelectedCategories([])
    setGeneratePdf(false)
  }

  function extractPower(productName) {
    const regex = /(\d+(\.\d+)?)[kKwW]/
    const match = productName.match(regex)
    return match ? parseFloat(match[1]) : null
  }

  const sortedProducts = [...products].sort((a, b) =>
    a.productName.localeCompare(b.productName, "en", { sensitivity: "base" })
  )

  const sortedForInvsAndPVs = [...sortedProducts].sort(
    (a, b) => extractPower(a.productName) - extractPower(b.productName)
  )

  const filterBrands = skuPart =>
    sortedForInvsAndPVs.filter(product => product.sku.includes(skuPart))

  const solisInv = filterBrands("SOL")
  const deyeInv = filterBrands("DEYE")

  const CategoryCoverPage = ({ category }) => (
    <Page size="A4" style={styles.coverPage}>
      <View style={styles.categoryCover}>
        <Image src={categoryToCoverMap[category]} style={styles.page_image} />
      </View>
    </Page>
  )

  const SelectedCategoriesDocument = ({ categories, products }) => {
    const onlyInv = products.filter(
      product => product.category === "Solar Inverter"
    )

    return (
      <Document fileName="Product Catalog.pdf">
        <Page size="A4" style={styles.coverPage}>
          <Image src={cover} style={styles.page_image} />
        </Page>
        {categories.map((category, idx) => (
          <React.Fragment key={idx}>
            <CategoryCoverPage category={category} />
            <Page size="A4" orientation="portrait" style={styles.page}>
              <View>
                <View fixed style={[styles.categoryHeader]}>
                  <Text
                    style={{ fontSize: 10, width: "33%", color: "#A9A9A9" }}
                  >
                    {category?.toUpperCase()}
                  </Text>
                  <View style={styles.line}></View>
                </View>
                <View style={[styles.cardContainer]}>
                  {category === "Solar Inverter" ? (
                    <>
                      {solisInv.map((product, idx) => (
                        <ProductCard key={idx} product={product} />
                      ))}
                      {deyeInv.map((product, idx) => (
                        <ProductCard key={idx} product={product} />
                      ))}
                    </>
                  ) : (
                    products
                      ?.filter(product => product?.category === category)
                      .map((product, idx) => (
                        <ProductCard key={idx} product={product} />
                      ))
                  )}
                </View>
              </View>

              <View style={styles.suppliersList} fixed>
                <Image src={img1} style={styles.supplier} />
                <Image src={img2} style={styles.supplier} />
                <Image src={img3} style={styles.supplier} />
                <Image src={img10} style={styles.supplier} />
                <Image src={img4} style={styles.supplier} />
                <Image src={img5} style={styles.supplier} />
                <Image src={img6} style={styles.supplier} />
                <Image src={img7} style={styles.supplier} />
                <Image src={img8} style={styles.supplier} />
                <Image src={img9} style={styles.supplier} />
              </View>
              <Text
                style={styles.pageNumber}
                fixed
                render={({ pageNumber, totalPages }) => `${pageNumber}`}
              />
            </Page>
          </React.Fragment>
        ))}
        <Page size="A4" style={styles.coverPage}>
          <Image src={back} style={styles.page_image} />
        </Page>
      </Document>
    )
  }

  const ProductCard = ({ product }) => (
    <View style={styles.card}>
      {product.productImages && product.productImages[0] ? (
        <>
          <Image
            src={product.productImages[0].fields.file.url + "?w=100"}
            style={styles.cardImage}
          />
          {/* <Image src={logoGray} style={styles.imageOverlay} /> */}
        </>
      ) : (
        <Image
          src={logo}
          alt="Product-img"
          objectFit="contain"
          style={styles.placeHolder}
        />
      )}
      <View style={styles.cardDetail}>
        <Text style={styles.productName}>{product.productName}</Text>
      </View>
      <View style={styles.cardDetail}>
        <Text style={styles.cardDetailsProp}>SKU</Text>
        <Text style={styles.cardDetailsData}>: {product.sku}</Text>
      </View>
      {product.productDescription !== "N/A" && (
        <View style={styles.cardDetail}>
          <Text style={styles.cardDetailsProp}>Description</Text>
          <Text style={styles.cardDetailsData}>
            : {product.productDescription}
          </Text>
        </View>
      )}
      <View style={styles.cardDetail}>
        <Text style={styles.cardDetailsProp}>Manufacturer</Text>
        <Text style={styles.cardDetailsData}>: {product.manufacturer}</Text>
      </View>
      <View style={styles.cardDetail}>
        <Text style={styles.cardDetailsProp}>Country Of Origin</Text>
        <Text style={styles.cardDetailsData}>: {product.countryOfOrigin}</Text>
      </View>
      {product.warranty && (
        <View style={styles.cardDetail}>
          <Text style={styles.cardDetailsProp}>Warranty</Text>
          <Text style={styles.cardDetailsData}>: {product.warranty}</Text>
        </View>
      )}
    </View>
  )

  return (
    <div>
      {isClient && showDownloadLink && (
        <>
          <h1 className="title mb-3">
            <FaFilePdf /> Download Brochure
          </h1>
          <div style={styles.formWindow}>
            <h3 className="text-lg font-bold mb-5">
              Please pick the categories to include in the brochure
            </h3>
            <Form>
              {categories.map(category => (
                <div
                  className="flex justify-between items-center"
                  key={category}
                >
                  <input
                    type="checkbox"
                    onChange={() => handleCategoryChange(category)}
                    checked={selectedCategories.includes(category)}
                  />
                  <label className="ml-3">{category}</label>
                </div>
              ))}
            </Form>
            <Button
              onClick={handleSubmit}
              disabled={selectedCategories.length === 0}
              size="lg"
              variant="primary"
              className="mt-4"
            >
              Submit to Download
            </Button>
          </div>
          {generatePdf && (
            <>
              <PDFDownloadLink
                document={
                  <SelectedCategoriesDocument
                    categories={selectedCategories}
                    products={sortedForInvsAndPVs}
                  />
                }
                fileName="Product Catalog.pdf"
              >
                {({ blob, url, loading, error }) => {
                  return loading ? (
                    <p
                      style={{
                        textTransform: "uppercase",
                        textAlign: "center",
                      }}
                    >
                      <Button
                        size="lg"
                        variant="secondary"
                        className="px-4 py-2 d-flex align-items-center justify-content-center"
                      >
                        Loading ...
                      </Button>
                    </p>
                  ) : (
                    <p
                      style={{
                        textTransform: "uppercase",
                        fontFamily: "Montserrat",
                        color: "#126b91",
                        fontWeight: "bold",
                      }}
                    >
                      <Button
                        size="lg"
                        variant="primary"
                        className="px-4 py-2 d-flex align-items-center justify-content-center"
                        onClick={handleDownloadComplete}
                      >
                        <FaDownload className="mr-2 " />
                        Download Product Catalog
                      </Button>
                    </p>
                  )
                }}
              </PDFDownloadLink>
            </>
          )}
        </>
      )}
    </div>
  )
}

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    paddingLeft: 30,
    paddingRight: 30,
    fontSize: 9,
    paddingTop: 40,
    paddingBottom: 65,
    fontFamily: "Montserrat",
    fontWeight: "normal",
    width: "100%",
  },

  coverPage: {
    position: "relative",
    width: "100%",
    height: "100%",
    padding: 0,
    backgroundColor: "white",
    fontSize: 9,
    color: "black",
    zIndex: -1,
  },

  page_image: {
    top: 0,
    left: 0,
    position: "absolute",
    width: "100%",
    height: "100%",
    objectFit: "cover",
    zIndex: 0,
  },

  categoryCover: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#f5f5f5",
  },

  categoryTitle: {
    fontSize: 24,
    fontWeight: "bold",
    color: "#333",
  },

  categoryHeader: {
    paddingRight: 30,
    paddingBottom: 15,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: `85%`,
  },

  line: {
    height: 1,
    backgroundColor: "#A9A9A9",
    marginLeft: 5,
    paddingRight: 30,
    width: `88%`,
  },

  cardContainer: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    position: "relative",
  },

  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "50%",
    height: 236.5,
    padding: 5,
    paddingHorizontal: 10,
    boxSizing: "border-box",
    border: true,
    borderColor: "#EEEEEE",
    position: "relative",
  },

  cardImage: {
    height: 105,
    padding: 10,
    margin: "auto",
  },

  imageOverlay: {
    position: "absolute",
    top: "25%",
    left: "20%",
    // transform: "rotate(-30deg)",
    opacity: 0.1,
    width: 150,
    zIndex: 100,
  },

  placeHolder: {
    width: 150,
    margin: "auto",
    opacity: 0.3,
  },

  detailsList: {
    height: 106.5,
    width: "100%",
    marginVertical: 10,
  },

  cardDetail: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginVertical: 1.5,
  },

  cardDetailsProp: {
    fontWeight: "semibold",
    width: "40%",
  },

  cardDetailsData: {
    width: "60%",
  },

  productName: {
    fontSize: 11,
    fontWeight: "bold",
    paddingVertical: 2,
    color: "#2980b9",
  },

  suppliersList: {
    display: "flex",
    flexDirection: "row",
    position: "absolute",
    left: 30,
    bottom: "10px",
  },

  supplier: {
    width: 46,
    marginRight: 6,
  },
  pageNumber: {
    position: "absolute",
    right: "30px",
    top: "40px",
    fontSize: 9,
    color: "grey",
  },

  formWindow: {
    border: "1px solid #ddd",
    borderRadius: "5px",
    padding: "25px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    marginBottom: "20px",
  },
  formCheckbox: {
    margin: "10px 0",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#ECECEC",
  },
})

export default DownloadBrochure
